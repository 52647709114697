const config = {

    // Landing Page
    landingcontent: {
        title: "Mexican Grill with the convenience of fast food!",
        subtitle: "Savoring Tradition: Fast, Flavorful Mexican Grill Delights at Don Taco!",
        description: "We take great care in preparing all of our foods at Don Taco to share our culinary traditions with you. Stop by to try them, and see what we have to offer.",
        keywords: "Homepage, Don Taco, Mexican grill, fast food, Carbondale, community, since 2004, inside seating, outside seating, expanded menu, Al Pastor, Barbacoa, Carnitas, traditional, culinary excellence, Horchata, rolled taquitos, homemade flour tortillas, signature, burritos, tacos, flavorful, convenience, unique fusion, warm atmosphere, welcoming, authenticity, quality, community, savor, rich tapestry, vibrant, mouthwatering, diverse, special touch, delicious, pride, heritage, tradition, fast-food convenience, transformative, journey, taste buds, celebration, warm, inviting, atmosphere, unique, blend, experience, signature, pride, flavorful fillings.",
    },

    // About Us Page
    aboutcontent: {
        title: "Taste The Don Taco Difference!",
        subtitle: "Stop by to see everything we have to offer.",
        description: "Savor the unique fusion of fast-food convenience and traditional Mexican culinary excellence at Don Taco, proudly serving the Carbondale community since 2004 with an expanded menu, featuring flavorful fillings like Al Pastor, Barbacoa, and Carnitas, all wrapped in our signature homemade flour tortillas",
        keywords: "About Us, Don Taco, Mexican grill, fast food, Carbondale, community, since 2004, inside seating, outside seating, expanded menu, Al Pastor, Barbacoa, Carnitas, traditional, culinary excellence, Horchata, rolled taquitos, homemade flour tortillas, signature, burritos, tacos, flavorful, convenience, unique fusion, warm atmosphere, welcoming, authenticity, quality, community, savor, rich tapestry, vibrant, mouthwatering, diverse, special touch, delicious, pride, heritage, tradition, fast-food convenience, transformative, journey, taste buds, celebration, warm, inviting, atmosphere, unique, blend, experience, signature, pride, flavorful fillings.",
        header: "Embark on a Culinary Journey at Don Taco – Est. 2004",
        message: "Proudly serving this community since 2004, Don Taco is now the only fast food Mexican grill in Carbondale with both inside and outside seating. We have expanded our menu at the new location and are proud to offer Al Pastor, Barbacoa, and Carnitas for fillings on our traditional menu items. Add on a refreshing Horchata or one of our delicious rolled taquitos to complete your meal! Did you know we even make our own flour tortillas? Available on burritos, tacos, and more, they will transform your taste buds.",
    },
    aboutbusiness: {
        paragraph1: "Indulge in the authentic flavors of Mexican cuisine like never before at Don Taco, a cherished part of the Carbondale community since 2004. Experience the Don Taco difference, where we've seamlessly blended the speed of fast food with the richness of a Mexican grill. At our newly expanded location, revel in the convenience of both inside and outside seating. Immerse yourself in the heart of Mexican culinary traditions with our diverse menu, now featuring Al Pastor, Barbacoa, and Carnitas as delectable fillings for our classic offerings.",
        paragraph2: "Quench your thirst with our refreshing Horchata or elevate your meal with our mouthwatering rolled taquitos. A special touch awaits your taste buds – our homemade flour tortillas, a signature addition to burritos, tacos, and more. Discover the joy of savoring tradition at Don Taco, where every bite tells a story of passion, quality, and community. Your culinary adventure awaits – come and Taste The Don Taco Difference!",
    },
    littleaboutus: {
        paragraph1: "We wanted to offer a 'Thank You' to everyone who helped making the dream of the the location on Grand Avenue a reality. We could not have done it without these fantastic local businesses!",
        paragraph2: "Also a thanks to Quality Sheet Metals, Inc., our glass company, and our amazing painting crew.",
        paragraph3: "We also could not provide all we do without our fantastic, dedicated staff, bringing you delicious food 7 days a week.",
    },

    // Contact Page
    contactcontent: {
        title: "Connect with Don Taco: Reach Out to Your Culinary Haven in Carbondale!",
        description: "Feel free to connect with us at Don Taco, a cherished part of the Carbondale community since 2004. Whether you have inquiries about our unique blend of fast-food convenience and traditional Mexican culinary excellence, want to explore our expanded menu featuring Al Pastor, Barbacoa, and Carnitas, or simply seek to immerse yourself in the vibrant atmosphere of our inside and outside seating areas, we're here for you. Your questions, feedback, and cravings are all welcome. Use the information below to reach out and experience the warmth, authenticity, and quality that define the Don Taco legacy. Let's embark on a flavorful journey together!",
        keywords: "Contact Us, Don Taco, Mexican grill, fast food, Carbondale, community, since 2004, inside seating, outside seating, expanded menu, Al Pastor, Barbacoa, Carnitas, traditional, culinary excellence, Horchata, rolled taquitos, homemade flour tortillas, signature, burritos, tacos, flavorful, convenience, unique fusion, warm atmosphere, welcoming, authenticity, quality, community, savor, rich tapestry, vibrant, mouthwatering, diverse, special touch, delicious, pride, heritage, tradition, fast-food convenience, transformative, journey, taste buds, celebration, warm, inviting, atmosphere, unique, blend, experience, signature, pride, flavorful fillings.",
        message: "Your Journey Starts Here – Don Taco, Where Tradition Meets Fast-Food Excellence"
    },

    // Photo Gallery Page
    gallerycontent: {
        title: "Visual Feast: Exploring the Flavors and Moments at Don Taco",
        description: "Dive into the vibrant world of Don Taco through our Photo Gallery, where we've curated a visual feast that mirrors the essence of our establishment. From the savory allure of Al Pastor, Barbacoa, and Carnitas to the inviting warmth of our inside and outside seating, each image tells a story of tradition, pride, and culinary excellence. Immerse yourself in the diverse flavors, the unique fusion of fast-food convenience, and the lively atmosphere that defines our legacy since 2004. Join us on a virtual journey through the lens of Don Taco, where every photo captures not just food but the heart and soul of our Carbondale community.",
        keywords: "Photo Gallery, Don Taco, Mexican grill, fast food, Carbondale, community, since 2004, inside seating, outside seating, expanded menu, Al Pastor, Barbacoa, Carnitas, traditional, culinary excellence, Horchata, rolled taquitos, homemade flour tortillas, signature, burritos, tacos, flavorful, convenience, unique fusion, warm atmosphere, welcoming, authenticity, quality, community, savor, rich tapestry, vibrant, mouthwatering, diverse, special touch, delicious, pride, heritage, tradition, fast-food convenience, transformative, journey, taste buds, celebration, warm, inviting, atmosphere, unique, blend, experience, signature, pride, flavorful fillings.",
        message: "Capturing Culinary Artistry and Community Spirit in Every Frame",
    },

    // Menu Page
    ordercontent: {
        title: "Flavors Unveiled: Don Taco's Culinary Symphony on Our Menu",
        description: "Savor Tradition, Elevate Taste – A Feast for Every Palate",
        keywords: "Menu Page, Don Taco, Mexican grill, fast food, Carbondale, community, since 2004, inside seating, outside seating, expanded menu, Al Pastor, Barbacoa, Carnitas, traditional, culinary excellence, Horchata, rolled taquitos, homemade flour tortillas, signature, burritos, tacos, flavorful, convenience, unique fusion, warm atmosphere, welcoming, authenticity, quality, community, savor, rich tapestry, vibrant, mouthwatering, diverse, special touch, delicious, pride, heritage, tradition, fast-food convenience, transformative, journey, taste buds, celebration, warm, inviting, atmosphere, unique, blend, experience, signature, pride, flavorful fillings.",
        message: "Savor the symphony of flavors at Don Taco since 2004, where the fusion of fast-food convenience and Mexican grill richness is celebrated through a diverse menu featuring highlights like Al Pastor, Barbacoa, and Carnitas, all wrapped in prideful homemade flour tortillas – a culinary journey and vibrant tribute to our Carbondale community",
    },

    // Eloyalty Page
    eloyaltycontent: {
        title: "Flavors Unveiled: Don Taco's Culinary Symphony on Our Menu",
        keywords: "Loyalty Page, Don Taco, Mexican grill, fast food, Carbondale, community, since 2004, inside seating, outside seating, expanded menu, Al Pastor, Barbacoa, Carnitas, traditional, culinary excellence, Horchata, rolled taquitos, homemade flour tortillas, signature, burritos, tacos, flavorful, convenience, unique fusion, warm atmosphere, welcoming, authenticity, quality, community, savor, rich tapestry, vibrant, mouthwatering, diverse, special touch, delicious, pride, heritage, tradition, fast-food convenience, transformative, journey, taste buds, celebration, warm, inviting, atmosphere, unique, blend, experience, signature, pride, flavorful fillings.",
        message: "Indulge your senses with the symphony of flavors showcased on the Don Taco Menu. Since 2004, we've proudly blended the convenience of fast food with the richness of a Mexican grill, offering a diverse selection that includes Al Pastor, Barbacoa, and Carnitas as highlights. Our commitment to authenticity and quality extends to the pride of homemade flour tortillas, a signature touch that transforms burritos, tacos, and more. Explore the culinary legacy of Don Taco, where each dish is a celebration of tradition and a vibrant tribute to our Carbondale community. From the first glance to the last bite, our menu is a journey worth savoring.",
    },
};

export default config;