import React from 'react';
import Logo1 from "../../../assets/images/1stmidil-bank-logo.webp";
import Logo2 from "../../../assets/images/jlp-mv2-logo.gif";
import Logo3 from "../../../assets/images/awplumbing-logo.webp";
import Logo4 from "../../../assets/images/burke-electric-logo.webp";
import {
  Container,
  Row,
  Col,
  Button,
  h5,
  p,
  ul,
  ol,
  h1,
  olstGroup,
} from "react-bootstrap";

export const PartnerCard = () => {
  return (
    <Container className="center whitespace footer">
        <Col xs={6} className="partner-title">
           <h3 className="">
            {" "}
            Partners
            </h3>
        </Col>    
      <Row className="whitespace">
        <Col xs={6} sm={6} lg={3} className="partner-img plzero">
        <ul>
            <ol><img className="App-logo" src={Logo1} alt="1stmidil-bank-logo" /></ol>
        </ul>
        </Col> 
        <Col xs={6} sm={6} lg={3} className="partner-img plzero">
        <ul>
            <ol><img className="App-logo" src={Logo2} alt="jlp-mv2-logo" /></ol>
        </ul>
        </Col> 
        <Col xs={6} sm={6} lg={3} className="partner-img plzero">
        <ul>
            <ol><img className="App-logo" src={Logo3} alt="awplumbing-logo" /></ol>
        </ul>
        </Col> 
        <Col xs={6} sm={6} lg={3} className="partner-img plzero">
        <ul>
            <ol><img className="App-logo" src={Logo4} alt="burke-electric-logo" /></ol>
        </ul>
        </Col> 
        </Row>  
     </Container>
  );
};
